export const translateModule = {
    namespaced: true,
    state: () => ({
      
    }),
    getters: {
      
    },
    mutations: {
      
    },
    actions: {
      
    }
}